<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search Amenities Name -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group label="Amenities" label-for="mc-select-date">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="getFilteredAmenities"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refContentListTable"
        class="position-relative"
        :items="items"
        responsive
        :search-options="{
          enabled: true,
          externalQuery: searchQuery
        }"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'amenities-edit',
                params: { id: data.item.groupId }
              }"
              :disabled="checkLoginRole() !== RoleEnum.SuperAdmin && !checkAccess.Edit"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing
              {{ totalAmenities >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalAmenities ? totalAmenities : endIndex }} of
              {{ totalAmenities }} entries</span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BTable,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { resConditionCheck, RoleEnum } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from '@/helpers/accessRightCheck'
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import amenitiesService from "../../../services/amenities/amenities.service";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      items: [],
      filteredItems: [],
      searchQuery: "",
      RoleEnum,
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      amenitiesId: "",
      totalAmenities: 0,
      checkLoginRole,
      checkAccess: {},
      tableColumns: [{ key: "name" }, { key: "actions" }]
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    // if (this.checkLoginRole() !== RoleEnum.SuperAdmin && !this.checkAccess.Edit) {
    this.tableColumns = [{ key: "name" }, { key: "actions" }];

    this.getAmenitiesListing();
  },

  methods: {
    async getAmenitiesListing() {
      this.contentLoading = true
      await amenitiesService
        .getAll()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.amenities) {
            this.contentLoading = false;
            this.items = res.data.amenities;
            this.filteredItems = res.data.amenities;
            this.totalAmenities = res.data.amenities.length;
            this.startIndex = 0;
            this.endIndex = this.startIndex + this.totalAmenities;
          }
        })
        .catch(error => {
          this.contentLoading = false;
          const errorData = errorResponseHandler(error);
          if (error.response.status === 404) {
            this.items = [];
            this.totalAmenities = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    getFilteredAmenities() {
      const text = this.searchQuery;
      if (text !== "") {
        this.items = this.filterAmenities(this.filteredItems, text);
        this.totalAmenities = this.items.length;
        this.startIndex = 0;
        this.endIndex = this.startIndex + this.totalAmenities;
      } else {
        this.getAmenitiesListing();
      }
    },

    filterAmenities(data, text) {
      return data
        .filter(item => {
          if (item.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item;
          }
          return false;
        })
        .sort();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
